<template>
  <section id="dashboard-ecommerce" />
</template>

<script>
export default {
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
